import { CSSProperties } from 'react';

import utils from './utils';


export const getString = (defaultString: string, customString?: string) => customString ? customString : defaultString

export const shadowStyles = {
    lightShadowColor: 'rgba(30,30,30,.05)',
    lightShadowHoverColor: 'rgba(30,30,30,.095)',
    darkShadowColor: 'rgba(0, 0, 0, 0.1)',
    darkShadowHoverColor: 'rgba(0, 0, 0, 0.175)', // box-shadow: 0px 4px 13px 0px #0000000A;
    lightShadow: (color?: any, inset?: boolean, custom?: string) => ({ // standard css shadow
        boxShadow: `${inset ? 'inset ' : ''}${custom ? custom : '0px 4px 13px 0px'} ${getString(allStyles.shadows.lightShadowColor, color)}`,
        MozBoxShadow: `${inset ? 'inset ' : ''}${custom ? custom : '0px 4px 13px 0px'} ${getString(allStyles.shadows.lightShadowColor, color)}`,
        WebkitBoxShadow: `${inset ? 'inset ' : ''}${custom ? custom : '0px 4px 13px 0px'} ${getString(allStyles.shadows.lightShadowColor, color)}`
    }),
    lightShadowHover: (color?: any, inset?: boolean, custom?: string) => ({ // standard css shadow when hovered (bit darker)
        boxShadow: `${inset ? 'inset ' : ''}${custom ? custom : '0 0 10px 0'} ${getString(allStyles.shadows.lightShadowHoverColor, color)}`,
        MozBoxShadow: `${inset ? 'inset ' : ''}${custom ? custom : '0 0 10px 0'} ${getString(allStyles.shadows.lightShadowHoverColor, color)}`,
        WebkitBoxShadow: `${inset ? 'inset ' : ''}${custom ? custom : '0 0 10px 0'} ${getString(allStyles.shadows.lightShadowHoverColor, color)}`
    }),
    darkShadow: (color?: any, inset?: boolean, custom?: string) => ({ // darker css shadow (usually for titles)
        boxShadow: `${inset ? 'inset ' : ''}${custom ? custom : '0px 2px 8px'} ${getString(allStyles.shadows.darkShadowColor, color)}`,
        MozBoxShadow: `${inset ? 'inset ' : ''}${custom ? custom : '0px 2px 8px'} ${getString(allStyles.shadows.darkShadowColor, color)}`,
        WebkitBoxShadow: `${inset ? 'inset ' : ''}${custom ? custom : '0px 2px 8px'} ${getString(allStyles.shadows.darkShadowColor, color)}`
    }),
    darkShadowHover: (color?: any, inset?: boolean, custom?: string) => ({ // darker css shadow when hovered (bit darker)
        boxShadow: `${inset ? 'inset ' : ''}${custom ? custom : '0px 4px 8px'} ${getString(allStyles.shadows.darkShadowHoverColor, color)}`,
        MozBoxShadow: `${inset ? 'inset ' : ''}${custom ? custom : '0px 4px 8px'} ${getString(allStyles.shadows.darkShadowHoverColor, color)}`,
        WebkitBoxShadow: `${inset ? 'inset ' : ''}${custom ? custom : '0px 4px 8px'} ${getString(allStyles.shadows.darkShadowHoverColor, color)}`
    }),
}

export const globalStyles = {
    inputStyle: () => ({ borderRadius: 6, ...allStyles.shadows.lightShadow() } as CSSProperties),
    buttonStyle: (style?: CSSProperties) => ({ borderRadius: 6, ...allStyles.shadows.lightShadow(), ...style } as CSSProperties),
    cardStyle: (config?: any) => ({ borderRadius: 6, ...(config?.shadow ? allStyles.shadows.lightShadow() : {}) } as CSSProperties),
    pageStyle: (config?: any) => ({
        paddingTop: `${config?.thin ? '34px' : '24px'}`,
        paddingBottom: `${config?.thin ? '34px' : '24px'}`,
        paddingLeft: `${config?.thin ? '32px' : '32px'}`,
        paddingRight: `${config?.thin ? '32px' : '32px'}`,
    } as CSSProperties),
    switchStyle: () => ({ ...allStyles.shadows.lightShadow() } as CSSProperties),
    subTitleText: (opacity?: number | string) => ({ color: colors.secondary.text(opacity) }),
    inputTitle: (opacity?: number | string) => ({ color: colors.secondary.text(opacity) }),
    tagStyle: ({ textColor, color, borderColor } = {} as any) => ({
        color: textColor || colors.tag.defaultText(),
        background: color || borderColor || colors.tag.default(),
        borderColor: color || colors.tag.default(),
        borderRadius: 6,
        ...utils.noMargin
    })
}

export const allStyles = {
    global: globalStyles,
    margins: {
        h: { margin: '0px 5px', },
        v: { margin: '5px 0px', },
        hc: (x: number) => ({ margin: `${x || 5}px 0px`, }),
        vc: (x: number) => ({ margin: `0px ${x || 5}px`, })
    },
    tags: {
        tagStyle: () => ({ ...allStyles.shadows.lightShadow(), ...allStyles.removeMargin })
    },
    shadows: shadowStyles,
    //? These are pretty much required to be render safe with ANTD columns and what not
    removeMargin: { marginBottom: 0, marginTop: 0, marginLeft: 0, marginRight: 0 },
    removePadding: { paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0 }
};

export const colors = {
    backgroundOther: () => utils.darkMode ? '#121212' : '#FAFAFA',
    background: () => utils.darkMode ? '#121212' : '#fff',
    white: () => utils.darkMode ? '#000000' : '#ffffff',
    black: () => utils.darkMode ? '#fff' : '#000000',
    tableText: () => utils.darkMode ? 'rgba(230,230,230,1)' : 'rgba(25, 25, 25, 1)',
    card: () => utils.darkMode ? '#121212' : '#fff',
    gCard: () => utils.darkMode ? '#FAFAFA10' : '#FAFAFA',
    inputBorder: () => utils.darkMode ? "rgb(48, 48, 48)" : "#EDF0F9",
    mainBorder: () => utils.darkMode ? "rgb(48, 48, 48)" : "#E6E8F0",
    text: {
        lowAlpha: () => utils.darkMode ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)',
        error: '#D6181A',
        white: () => utils.darkMode ? '#000000' : '#ffffff',
        black: () => utils.darkMode ? '#fff' : '#000000',
        deep: () => utils.darkMode ? 'rgba(230,230,230,1)' : 'rgba(25, 25, 25, 1)',
        light: () => utils.darkMode ? '#ffffff80' : '#4A4A4D',
        lighter: () => utils.darkMode ? '#ffffff80' : '#7b7b80',
        lighterer: () => utils.darkMode ? '#B5B5BD80' : '#B5B5BD',
        g939399: () => utils.darkMode ? '#939399' : '#939399',
        g4A4A4D: () => utils.darkMode ? '#ffffff80' : '#4A4A4D',
        gB5B5BD: () => utils.darkMode ? '#B5B5BD80' : '#B5B5BD',
        g7B7B80: () => utils.darkMode ? '#ffffff80' : '#7b7b80',
        rFB4C7E: '#FB4C7E',
        neutral300: '#C4C5CC'
    },
    tag: {
        default: () => utils.darkMode ? '#F5F7FF80' : '#F5F7FF',
        defaultText: () => utils.darkMode ? '#fff' : '#4A4A4D'
    },



    progressBlue: '#96CDFF',

    linkBlue: 'var(--info)',


    primary: {
        primary: "#6262F5",
        primaryO: (opacity: number | string) => `rgba(98,98,245,${opacity || 1.0})`,

        info: 'var(--info)',
        success: 'var(--success)',
        secondary900: '#0073DE',
        warning: '#FF7A00',
        blue: '#1890ff',
        green: 'var(--success)',
        pinkRed: 'var(--danger)',
        pink: 'var(--danger)',
        // pinkRed: '#ff4d7e',
        // pink: '#FB4C7E',
        error: '#D6181A',
        error700: '#FA4D4F',
        error500: '#FA7577',
        warning500: '#FFA24D',
        warning900: '#BD6413',
        yellow: '#faad14',
        blueO: (opacity: number | string) => `rgba(24,144,255,${opacity || 1.0})`,
        greenO: (opacity: number | string) => `rgba(19,207,128,${opacity || 1.0})`,
        pinkRedO: (opacity: number | string) => `rgba(255,77,126,${opacity || 1.0})`,
        pinkO: (opacity: number | string) => `rgba(251,76,126,${opacity || 1.0})`,
        errorO: (opacity: number | string) => `rgba(214,24,26,${(opacity || 1.0)})`,
        dataYellow: '#E9ED24',
        dataOrange: '#FF7A00',
        orange: '#FF922E',
        recipe: '#7378F5',
        ecomPurple: '#6F4CFB',
        debug: '#31C4FF',
        dividerColor: () => utils.darkMode ? '#3a3a3a' : '#f0f0f0',
        pageDividerColor: () => utils.darkMode ? '#3a3a3a' : '#E6E8F0',

    },
    secondary: {
        blue: '#316ce8',
        green: '#34b87d',
        text: (opacity?: number | string) => utils.darkMode ? `rgba(255, 255, 255, ${opacity || 0.5})` : `rgba(0, 0, 0, ${opacity || 0.5})`
    },
    greens: ['#45bb86'],
    reds: ['#ff6a5b'], // FFA500
    pallet: ['#2CDE9D', '#31C4FF', '#E3F525', '#E8A056', '#FF57AE', '#7378F5', '#EFF576', '#E89C56', '#FF78B1'],
    palletDarker: ['#1c9166', '#00577a', '#636c05', '#70400f', '#890047', '#0a0e86', '#81870a', '#70400f', '#96003f'],
    palletTwo: ['#de2c6d', '#ff6c31', '#c4ff31', '#31ff6c', '#6c31ff',],
    palletTwoDarker: ['#6f1134', '#7f2500', '#5b7f00', '#007f25', '#25007f',],


    analytics: {
        gradientCalc(items: number, type: 'even' | 'step' = 'even'): { [key: string]: string } {
            const gradient: { [key: string]: string } = {
                "#20CF80": "#1aa666",
                "#1DD29D": "#17a87e",
                "#19D4B5": "#14aa91",
                "#16D7CE": "#12aca5",
                "#12D9E6": "#0eaeb8",
                "#12CBF0": "#0ca4c2",
                "#14B3F1": "#0c91c5",
                "#169CF2": "#0b7ec8",
                "#1885F3": "#0b6acb",
                "#1B6BF2": "#0c53cc",
                "#355CF1": "#0f3adc",
                "#524EEC": "#1d18e3",
                "#6F3FE8": "#4e19d3",
                "#8E30E3": "#721ac2",
                "#AB22DF": "#891ab4",
                "#BA28CF": "#9520a6",
                "#C930BD": "#a12697",
                "#D839AB": "#b6248d",
                "#E74198": "#d21b7b",
                "#FB4C7E": "#fa0c50",
            };

            const gradientKeys = Object.keys(gradient);
            const numColors = gradientKeys.length;

            const selectedColors: { [key: string]: string } = {};

            if (items <= numColors) {
                const step = type === 'even' ? Math.floor(numColors / (items - 1)) : numColors / items;
                for (let i = 0; i < items; i++) {
                    const colorIndex = Math.min(Math.floor(i * step), numColors - 1);
                    selectedColors[gradientKeys[colorIndex]] = gradient[gradientKeys[colorIndex]];
                }
            } else {
                for (let i = 0; i < items; i++) {
                    const position = i * (numColors - 1) / (items - 1);
                    const startColorIdx = Math.floor(position);
                    const endColorIdx = Math.ceil(position);
                    const factor = position - startColorIdx;
                    const interpolatedColor = interpolateColor(
                        gradient[gradientKeys[startColorIdx]],
                        gradient[gradientKeys[endColorIdx]],
                        factor
                    );
                    selectedColors[interpolatedColor] = interpolatedColor;
                }
            }

            return selectedColors;
        },
        gradient: {
            "#20CF80": "#1aa666",
            "#1DD29D": "#17a87e",
            "#19D4B5": "#14aa91",
            "#16D7CE": "#12aca5",
            "#12D9E6": "#0eaeb8",
            "#12CBF0": "#0ca4c2",
            "#14B3F1": "#0c91c5",
            "#169CF2": "#0b7ec8",
            "#1885F3": "#0b6acb",
            "#1B6BF2": "#0c53cc",
            "#355CF1": "#0f3adc",
            "#524EEC": "#1d18e3",
            "#6F3FE8": "#4e19d3",
            "#8E30E3": "#721ac2",
            "#AB22DF": "#891ab4",
            "#BA28CF": "#9520a6",
            "#C930BD": "#a12697",
            "#D839AB": "#b6248d",
            "#E74198": "#d21b7b",
            "#FB4C7E": "#fa0c50",
        },
        gradientTenShade: {
            "#20CF80": "#1aa666",
            "#19D4B5": "#14aa91",
            "#12D9E6": "#0eaeb8",
            "#14B3F1": "#0c91c5",
            "#1885F3": "#0b6acb",
            "#355CF1": "#0f3adc",
            "#6F3FE8": "#4e19d3",
            "#AB22DF": "#891ab4",
            "#C930BD": "#a12697",
            "#E74198": "#d21b7b",
        },
        gradientFiveShade: {
            "#20CF80": "#1aa666",
            "#12D9E6": "#0eaeb8",
            "#1885F3": "#0b6acb",
            // "#6F3FE8": "#4e19d3",
            "#C930BD": "#a12697",
            "#E74198": "#d21b7b",
        },


    },

    colorMap: {
        90: '#ff4d4f',
        80: '#f26359',
        70: '#e57461',
        60: '#d78369',
        50: '#d78369',
        // 50: '#c89170',
        // 40: '#b79d77',
        40: '#91b484',
        30: '#79be8a',
        20: '#5bc890',
        10: '#2dd195',
        0: '#2dd195',
    },
}

// Blend to colors together %
export const RGB_Linear_Blend = (p: number, c0: string, c1: string) => {
    var i = parseInt, r = Math.round, P = 1 - p, [a, b, c, d] = c0.split(","), [e, f, g, h] = c1.split(","), x = d || h, j = x ? "," + (!d ? h : !h ? d : r((parseFloat(d) * P + parseFloat(h) * p) * 1000) / 1000 + ")") : ")";
    return "rgb" + (x ? "a(" : "(") + r(i(a[3] == "a" ? a.slice(5) : a.slice(4)) * P + i(e[3] == "a" ? e.slice(5) : e.slice(4)) * p) + "," + r(i(b) * P + i(f) * p) + "," + r(i(c) * P + i(g) * p) + j;
}
// Shade/darken color  %
export const RGB_Linear_Shade = (p: number, c: string) => {
    var i = parseInt, r = Math.round, [a, b, c, d] = c.split(","), P: any = p < 0, t = P ? 0 : 255 * p, P: any = P ? 1 + p : 1 - p;
    return "rgb" + (d ? "a(" : "(") + r(i(a[3] == "a" ? a.slice(5) : a.slice(4)) * P + t) + "," + r(i(b) * P + t) + "," + r(i(c) * P + t) + (d ? "," + d : ")");
}
// Blend to colors together %
export const RGB_Log_Blend = (p: number, c0: string, c1: string) => {
    var i = parseInt, r = Math.round, P = 1 - p, [a, b, c, d] = c0.split(","), [e, f, g, h] = c1.split(","), x = d || h, j = x ? "," + (!d ? h : !h ? d : r((parseFloat(d) * P + parseFloat(h) * p) * 1000) / 1000 + ")") : ")";
    return "rgb" + (x ? "a(" : "(") + r((P * i(a[3] == "a" ? a.slice(5) : a.slice(4)) ** 2 + p * i(e[3] == "a" ? e.slice(5) : e.slice(4)) ** 2) ** 0.5) + "," + r((P * i(b) ** 2 + p * i(f) ** 2) ** 0.5) + "," + r((P * i(c) ** 2 + p * i(g) ** 2) ** 0.5) + j;
}
// Shade/darken color  %
export const RGB_Log_Shade = (p: number, c: string) => {
    var i = parseInt, r = Math.round, [a, b, c, d] = c.split(","), P: any = p < 0, t = P ? 0 : p * 255 ** 2, P: any = P ? 1 + p : 1 - p;
    return "rgb" + (d ? "a(" : "(") + r((P * i(a[3] == "a" ? a.slice(5) : a.slice(4)) ** 2 + t) ** 0.5) + "," + r((P * i(b) ** 2 + t) ** 0.5) + "," + r((P * i(c) ** 2 + t) ** 0.5) + (d ? "," + d : ")");
}
export type Color = [number, number, number];

export function getBestTextColor(backgroundColor: string): string {
    // Helper function to convert a hex color to RGB components
    const hexToRgb = (hex: string): { r: number, g: number, b: number } => {
        // Remove the leading # if it's there
        hex = hex.replace(/^#/, '');

        // Parse the string and convert to integer
        const r = Number.parseInt(hex.substring(0, 2), 16);
        const g = Number.parseInt(hex.substring(2, 4), 16);
        const b = Number.parseInt(hex.substring(4, 6), 16);

        return { r, g, b };
    };

    // Helper function to calculate the relative luminance of a color
    const luminance = (r: number, g: number, b: number): number => {
        const a = [r, g, b].map((v) => {
            v /= 255;
            return v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4;
        });
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    };

    // Convert the background color to RGB
    const { r, g, b } = hexToRgb(backgroundColor);

    // Calculate the luminance
    const bgLuminance = luminance(r, g, b);

    // Return either black or white depending on which contrasts better with the background
    return bgLuminance > 0.5 ? '#000000' : '#FFFFFF';
}

export const colorUtils = {
    // Convert a hex color code to an RGB color value
    hexToRgb: (hex: string, options?: { objectFormat?: boolean, shortObject?: boolean }) => {
        const cleanHex = hex.replace('#', '');
        const hexValue = parseInt(cleanHex, 16);
        const red = (hexValue >> 16) & 255;
        const green = (hexValue >> 8) & 255;
        const blue = hexValue & 255;
        if (options?.objectFormat) return { red, green, blue } as { red: number, green: number, blue: number };
        if (options?.shortObject) return { r: red, g: green, b: blue } as { r: number, g: number, b: number };
        return [red, green, blue] as Color;
    },
    // Convert an RGB color value to a hex color code
    rgbToHex: (r: number, g: number, b: number) => "#" + (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1),
    // Set the alpha value of a hex color code and return the result as an RGBA color value
    setHexAlpha: (hex?: string, alpha?: number) => {
        if (!hex || !alpha) return ''
        const rgb = colorUtils.hexToRgb(hex), [r, g, b] = (rgb || []) as Color
        if (!rgb || !(rgb as Color).length) return ''
        return `rgba(${r},${g},${b},${alpha})`
    },
    // Check if a string is a valid hex color code
    isHexColor: (input: string): boolean => /^#([0-9A-F]{3}){1,2}$/i.test(input),
    // Check if a string is a valid color name
    isColorName: (input: string): boolean => {
        const colorNames = ['black', 'silver', 'gray', 'white', 'maroon', 'red', 'purple', 'fuchsia', 'green', 'lime', 'olive', 'yellow', 'navy', 'blue', 'teal', 'aqua'];
        return colorNames.includes(input.toLowerCase());
    },
    // Convert a color name to an RGB color value
    colorNameToRgb: (name: string): Color => {
        switch (name.toLowerCase()) {
            case 'black':
                return [0, 0, 0];
            case 'silver':
                return [192, 192, 192];
            case 'gray':
                return [128, 128, 128];
            case 'white':
                return [255, 255, 255];
            case 'maroon':
                return [128, 0, 0];
            case 'red':
                return [255, 0, 0];
            case 'purple':
                return [128, 0, 128];
            case 'fuchsia':
                return [255, 0, 255];
            case 'green':
                return [0, 128, 0];
            case 'lime':
                return [0, 255, 0];
            case 'olive':
                return [128, 128, 0];
            case 'yellow':
                return [255, 255, 0];
            case 'navy':
                return [0, 0, 128];
            case 'blue':
                return [0, 0, 255];
            case 'teal':
                return [0, 128, 128];
            case 'aqua':
                return [0, 255, 255];
            default:
                throw new Error('Unknown color name');
        }
    },
    /**
    * Parses a string representing a color and returns a Color object or null.
    *
    * @param {string} input - The color string to parse.
    * @returns {Color | null} - The parsed Color object or null if the input is invalid.
    */
    parseColor: (input: string): Color | null => {
        if (colorUtils.isHexColor(input)) {
            return colorUtils.hexToRgb(input) as Color;
        } else if (colorUtils.isColorName(input)) {
            return colorUtils.colorNameToRgb(input);
        } else {
            return null
        }
    },
    getTextColorBasedOnBgColor(hexColor: string) {
        // Convert hex to RGB first
        let r = parseInt(hexColor.substr(1, 2), 16);
        let g = parseInt(hexColor.substr(3, 2), 16);
        let b = parseInt(hexColor.substr(5, 2), 16);

        // Calculate luminance
        const luminance = 0.299 * r + 0.587 * g + 0.114 * b;

        // If luminance is less than 150, text color should be white, otherwise black
        return luminance < 150 ? '#FFFFFF' : '#000000';
    }
}

export function colorToHex(color: string) {
    // Remove whitespace and convert to lowercase
    color = color.trim().toLowerCase();

    // Check if the color is in the rgba format
    if (color.startsWith("rgba")) {
        // Extract the RGB values from the rgba string
        const match = color.match(/(\d+),\s*(\d+),\s*(\d+),\s*(\d+(\.\d+)?)\)$/);
        if (match) {
            const r = parseInt(match[1]);
            const g = parseInt(match[2]);
            const b = parseInt(match[3]);

            // Convert RGB to hex
            const hex = ((r << 16) | (g << 8) | b).toString(16).padStart(6, "0");
            return `#${hex}`;
        }
    }

    // Check if the color is in the hexadecimal format
    if (color.startsWith("#")) {
        // Remove the hash symbol if present
        color = color.slice(1);
    }

    // If the color is already in a valid 6-digit hexadecimal format
    if (/^[0-9a-f]{6}$/i.test(color)) {
        return `#${color}`;
    }

    // Check if the color name exists in the colorNames object
    if (colorNames.hasOwnProperty(color)) {
        return `#${colorNames[color]}`;
    }

    // If none of the format checks match, return null
    return null;
}

// Handle color names
export const colorNames: { [str: string]: string } = {
    aliceblue: "f0f8ff",
    antiquewhite: "faebd7",
    aqua: "00ffff",
    aquamarine: "7fffd4",
    azure: "f0ffff",
    beige: "f5f5dc",
    bisque: "ffe4c4",
    black: "000000",
    blanchedalmond: "ffebcd",
    blue: "0000ff",
    blueviolet: "8a2be2",
    brown: "a52a2a",
    burlywood: "deb887",
    cadetblue: "5f9ea0",
    chartreuse: "7fff00",
    chocolate: "d2691e",
    coral: "ff7f50",
    cornflowerblue: "6495ed",
    cornsilk: "fff8dc",
    crimson: "dc143c",
    cyan: "00ffff",
    darkblue: "00008b",
    darkcyan: "008b8b",
    darkgoldenrod: "b8860b",
    darkgray: "a9a9a9",
    darkgreen: "006400",
    darkkhaki: "bdb76b",
    darkmagenta: "8b008b",
    darkolivegreen: "556b2f",
    darkorange: "ff8c00",
    darkorchid: "9932cc",
    darkred: "8b0000",
    darksalmon: "e9967a",
    darkseagreen: "8fbc8f",
    darkslateblue: "483d8b",
    darkslategray: "2f4f4f",
    darkturquoise: "00ced1",
    darkviolet: "9400d3",
    deeppink: "ff1493",
    deepskyblue: "00bfff",
    dimgray: "696969",
    dodgerblue: "1e90ff",
    firebrick: "b22222",
    floralwhite: "fffaf0",
    forestgreen: "228b22",
    fuchsia: "ff00ff",
    gainsboro: "dcdcdc",
    ghostwhite: "f8f8ff",
    gold: "ffd700",
    goldenrod: "daa520",
    gray: "808080",
    green: "008000",
    greenyellow: "adff2f",
    honeydew: "f0fff0",
    hotpink: "ff69b4",
    indianred: "cd5c5c",
    indigo: "4b0082",
    ivory: "fffff0",
    khaki: "f0e68c",
    lavender: "e6e6fa",
    lavenderblush: "fff0f5",
    lawngreen: "7cfc00",
    lemonchiffon: "fffacd",
    lightblue: "add8e6",
    lightcoral: "f08080",
    lightcyan: "e0ffff",
    lightgoldenrodyellow: "fafad2",
    lightgray: "d3d3d3",
    lightgreen: "90ee90",
    lightpink: "ffb6c1",
    lightsalmon: "ffa07a",
    lightseagreen: "20b2aa",
    lightskyblue: "87cefa",
    lightslategray: "778899",
    lightsteelblue: "b0c4de",
    lightyellow: "ffffe0",
    lime: "00ff00",
    limegreen: "32cd32",
    linen: "faf0e6",
    magenta: "ff00ff",
    maroon: "800000",
    mediumaquamarine: "66cdaa",
    mediumblue: "0000cd",
    mediumorchid: "ba55d3",
    mediumpurple: "9370db",
    mediumseagreen: "3cb371",
    mediumslateblue: "7b68ee",
    mediumspringgreen: "00fa9a",
    mediumturquoise: "48d1cc",
    mediumvioletred: "c71585",
    midnightblue: "191970",
    mintcream: "f5fffa",
    mistyrose: "ffe4e1",
    moccasin: "ffe4b5",
    navajowhite: "ffdead",
    navy: "000080",
    oldlace: "fdf5e6",
    olive: "808000",
    olivedrab: "6b8e23",
    orange: "ffa500",
    orangered: "ff4500",
    orchid: "da70d6",
    palegoldenrod: "eee8aa",
    palegreen: "98fb98",
    paleturquoise: "afeeee",
    palevioletred: "db7093",
    papayawhip: "ffefd5",
    peachpuff: "ffdab9",
    peru: "cd853f",
    pink: "ffc0cb",
    plum: "dda0dd",
    powderblue: "b0e0e6",
    purple: "800080",
    rebeccapurple: "663399",
    red: "ff0000",
    rosybrown: "bc8f8f",
    royalblue: "4169e1",
    saddlebrown: "8b4513",
    salmon: "fa8072",
    sandybrown: "f4a460",
    seagreen: "2e8b57",
    seashell: "fff5ee",
    sienna: "a0522d",
    silver: "c0c0c0",
    skyblue: "87ceeb",
    slateblue: "6a5acd",
    slategray: "708090",
    snow: "fffafa",
    springgreen: "00ff7f",
    steelblue: "4682b4",
    tan: "d2b48c",
    teal: "008080",
    thistle: "d8bfd8",
    tomato: "ff6347",
    turquoise: "40e0d0",
    violet: "ee82ee",
    wheat: "f5deb3",
    white: "ffffff",
    whitesmoke: "f5f5f5",
    yellow: "ffff00",
    yellowgreen: "9acd32"
};

function interpolateColor(color1: string, color2: string, factor: number): string {
    const a = parseInt(color1.slice(1, 3), 16);
    const b = parseInt(color1.slice(3, 5), 16);
    const c = parseInt(color1.slice(5, 7), 16);

    const x = parseInt(color2.slice(1, 3), 16);
    const y = parseInt(color2.slice(3, 5), 16);
    const z = parseInt(color2.slice(5, 7), 16);

    const red = Math.round(a + factor * (x - a)).toString(16).padStart(2, '0');
    const green = Math.round(b + factor * (y - b)).toString(16).padStart(2, '0');
    const blue = Math.round(c + factor * (z - c)).toString(16).padStart(2, '0');

    return '#' + red + green + blue;
}

const cssSizeWords = ['auto', 'inherit', 'initial', 'unset', 'rem', 'em', 'px', 'var', "%"]
export function cssNum(value: number | string | undefined): string {
    if (value === undefined) return '';
    const strify = `${value ?? 0}`.trim().toLowerCase();
    if (cssSizeWords.some(word => strify.includes(word))) return strify;
    return `${strify}px`;
}


export default allStyles
