import React from 'react';
import { message, notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';

import { clean } from './';

export const notificationTypes = ['success', 'info', 'warning', 'error'] as const;
export type NotificationType = (typeof notificationTypes)[number];

const colors: Record<NotificationType, string> = {
	info: '#1890FF',
	success: 'var(--success)',
	error: '#ff4d7e',
	warning: '#E89C56',
};

const defaultMessage: Record<NotificationType, string> = {
	success: 'Success',
	error: 'Error',
	warning: 'Warning',
	info: 'Info',
};

export interface NotificationConfig extends ArgsProps {
	type?: NotificationType;
	color?: string;
}

type NotificationAPIArgs = [message: string, description?: string, config?: Partial<NotificationConfig>] | [config: Partial<NotificationConfig>];

export const NotificationAPI = {
	send(config = {} as Partial<NotificationConfig>): [string, () => void] {
		const type = config.type || 'success';
		const description = config.description || '';
		const message = config.message || defaultMessage[type];
		const color = config?.color || colors[type] || '';

		const key = config.key || clean(description);

		notification[type]({
			...config,
			duration: config.duration !== undefined ? config.duration : 8,
			maxCount: config.maxCount || 1,
			style: { borderLeft: color ? `3px solid ${color}` : '', ...config.style },
			message: typeof message === 'string' ? <span style={{ fontSize: '1rem', fontWeight: 600 }}>{message}</span> : message,
			description: typeof description === 'string' ? <span style={{ fontSize: '.875rem', fontWeight: 400 }}>{description}</span> : description,
			key,
		});

		return [key, () => notification.close(key)];
	},
	success(...args: NotificationAPIArgs) {
		const [message, description, config] = args;
		if (args.length === 1 && !React.isValidElement(message)) return NotificationAPI.send({ type: 'success', ...(message as Partial<NotificationConfig>) });
		return NotificationAPI.send({ type: 'success', message, description, ...config });
	},

	error(...args: NotificationAPIArgs) {
		const [message, description, config] = args;
		if (args.length === 1 && !React.isValidElement(message)) return NotificationAPI.send({ type: 'error', ...(message as Partial<NotificationConfig>) });
		return NotificationAPI.send({ type: 'error', message, description, ...config });
	},

	warning(...args: NotificationAPIArgs) {
		const [message, description, config] = args;
		if (args.length === 1 && !React.isValidElement(message)) return NotificationAPI.send({ type: 'warning', ...(message as Partial<NotificationConfig>) });
		return NotificationAPI.send({ type: 'warning', message, description, ...config });
	},

	info(...args: NotificationAPIArgs) {
		const [message, description, config] = args;
		if (args.length === 1 && !React.isValidElement(message)) return NotificationAPI.send({ type: 'info', ...(message as Partial<NotificationConfig>) });
		return NotificationAPI.send({ type: 'info', message, description, ...config });
	},

	close(key: string) {
		notification.close(key);
	},
};

export interface PopupConfig extends ArgsProps {
	type?: NotificationType;
	color?: string;
}

export const PopupMessage = {
	send(config = {} as Partial<PopupConfig>) {
		const type = config.type || 'success';
		const description = config.description || '';
		const color = config?.color || colors[type] || '';

		const key = config.key || clean(description);

		message[type]({
			key,
			duration: config.duration !== undefined ? config.duration : 8,
			maxCount: config.maxCount || 1,
			style: { borderLeft: color ? `3px solid ${color}` : '', ...config.style },
			message: typeof config.message === 'string' ? <span style={{ fontSize: '1rem', fontWeight: 600 }}>{config.message}</span> : config.message,
			description: typeof description === 'string' ? <span style={{ fontSize: '.875rem', fontWeight: 400 }}>{description}</span> : description,
			content: typeof description === 'string' ? <span style={{ fontSize: '.875rem', fontWeight: 400 }}>{description}</span> : description,
		});

		return [key, () => message.destroy(key)];
	},

	success(message: string, description?: string, config?: Partial<PopupConfig>) {
		return PopupMessage.send({ type: 'success', message, description, ...config });
	},

	error(message: string, description?: string, config?: Partial<PopupConfig>) {
		return PopupMessage.send({ type: 'error', message, description, ...config });
	},

	warning(message: string, description?: string, config?: Partial<PopupConfig>) {
		return PopupMessage.send({ type: 'warning', message, description, ...config });
	},

	info(message: string, description?: string, config?: Partial<PopupConfig>) {
		return PopupMessage.send({ type: 'info', message, description, ...config });
	},

	close(key: string) {
		message.destroy(key);
	},
};

// @ts-ignore
window['PopupMessage'] = PopupMessage;
